import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from 'uuid';

import { Message, MessageToServer, MessageToClient, MessageClient } from "../types";

export type WSStatus = "connecting" | "connected" | "closed" | "error";

export class WS<Message> {
    private id: string;
    private master: string = "";
    private ws: WebSocket;
    private channel: string;
    private status: WSStatus;
    private timeout = setTimeout(() => { }, 0);
    private listeners: Array<(message: MessageClient<Message>) => void> = [];
    private onStatus = (status: WSStatus) => { };

    constructor(url: string, channel: string, id: string = uuid()) {
        this.id = id;
        this.channel = channel;
        this.status = "closed";
        this.ws = this.setWebSocket(url);

        this.addEventListeners();
    }

    private setWebSocket(url: string) {
        this.changeStatus("connecting");
        this.ws = new WebSocket(`${url}?id=${encodeURIComponent(this.id)}`);

        this.ws.onerror = (error) => {
            this.changeStatus("closed");
            console.error(error);
            this.ws.close();
            // setTimeout(() => this.setWebSocket(url), 100 + Math.random() * 1000);
        }
        this.ws.onclose = () => {
            this.changeStatus("closed");
            this.master = "";
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setWebSocket(url), 100 + Math.random() * 500);
        }

        this.ws.onopen = () => {
            this.changeStatus("connected");
            if (this.channel)
                this.setChannel(this.channel);
        }

        this.ws.onmessage = (e: MessageEvent<string>) => {
            try {
                const message = JSON.parse(e.data) as MessageToClient<Message>;

                if (message.type === "enter" || message.type === "leave") {
                    this.master = message.master;
                    const isMaster = this.id === this.master;
                    this.listeners.forEach(listener => listener({ type: message.type, id: message.id, isMaster }));
                } else if (message.type === "message") {
                    this.master = message.master;
                    const isMaster = this.id === this.master;
                    this.listeners.forEach(listener => listener({ type: message.type, isMaster, message: message.message }));
                } else if (message.type === "error") {
                    this.changeStatus("error");
                    this.ws.close();
                } else {
                    throw new Error(`Invalid message type ${e.data}"`);
                }
            } catch (error) {
                console.error(error);
            }
        }

        return this.ws;
    }

    private addEventListeners() {
        const wsClose = () => {
            console.log("beforeunload, unload, pagehide:", this.id);
            this.ws.close();
        }
        window.addEventListener("beforeunload", wsClose, false);
        window.addEventListener("pagehide", wsClose, false);
        window.addEventListener("visibilitychange", () => {
            if (document.visibilityState !== "visible") {
                wsClose();
            }
        }, false);
    }

    private changeStatus(status: WSStatus) {
        this.status = status;
        this.onStatus(status);
    }

    public getId() {
        return this.id;
    }

    public getMaster() {
        return this.master;
    }

    public setChannel(channel: string) {
        this.channel = channel;
        const messageToServer: MessageToServer<Message> = { type: "channel", channel };
        this.ws.send(JSON.stringify(messageToServer));
    }

    public getStatus() {
        return this.status;
    }

    public onStatusChange(callback: (status: "connecting" | "connected" | "closed" | "error") => void) {
        this.onStatus = callback;
    }

    public send(message: Message) {
        if (this.status !== "connected") {
            console.error("WS is not connected and is sending:", message);
        }
        const messageToServer: MessageToServer<Message> = { type: "message", message };
        this.ws.send(JSON.stringify(messageToServer));
    }

    public addListener(callback: (message: MessageClient<Message>) => void) {
        this.listeners.push(callback);
    }

    public removeListener(callback: (message: MessageClient<Message>) => void) {
        this.listeners = this.listeners.filter(listener => listener !== callback);
    }
}

export type WSContextType<Message> = {
    ws: WS<Message>,
    status: WSStatus,
    channel: string,
    sendMessage: (message: Message) => void,
    setChannel: (channel: string) => void,
};

// set initial channel from url as table param
const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const table = params.get("table");

// after that from localStorage
// default channel is "Waiting room"
const initChannel = table || localStorage.getItem("channel") || "Waiting room";

const WSContext = createContext<WSContextType<Message>>({
    ws: {} as any,
    status: "connecting",
    channel: initChannel,
    sendMessage: () => { },
    setChannel: () => { },
});

export function WSProvider({ children, url }: { children: any, url: string }) {
    const [channel, setChannel] = useState<string>(initChannel);
    const [status, setStatus] = useState<WSStatus>("connecting");

    const ws = useMemo(() => {
        const id = localStorage.getItem("client-id") || undefined;
        const ws = new WS<Message>(url, initChannel, id);

        localStorage.setItem("client-id", ws.getId());
        ws.onStatusChange(setStatus);
        return ws;
    }, [url]);

    const sendMessage = useMemo(() => (message: Message) => {
        ws.send(message);
    }, [ws]);

    const setChannelAndSend = useMemo(() => (channel: string) => {
        localStorage.setItem("channel", channel);
        setChannel(channel);
        ws.setChannel(channel);
    }, [ws]);

    return <WSContext.Provider value={{
        ws,
        status,
        channel,
        sendMessage,
        setChannel: setChannelAndSend,
    }}>
        {children}
    </WSContext.Provider>
}

export function useWS() {
    return useContext(WSContext);
}

export function useWSOnMessage(onMessage: (message: MessageClient<Message>) => void, deps: React.DependencyList) {
    const { ws } = useWS();

    useEffect(() => {
        ws.addListener(onMessage);

        return () => {
            ws.removeListener(onMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
