import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'rc-time-picker/assets/index.css';

import App from './App';
import { WSProvider } from './WS';

const url = new URL(window.location.href);
const host = url.hostname;
// const port = url.port;
const wsUrl = process.env.NODE_ENV === 'production' ? `wss://${host}` : `ws://localhost:8910`; // `ws://${host}:${port}`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<WSProvider url={wsUrl}>
  <App />
</WSProvider>);
