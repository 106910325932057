
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, throttle } from 'lodash';
import moment, { Moment } from 'moment';
import TimePicker from 'rc-time-picker';

import { useWS, useWSOnMessage } from './WS';

import { Player, State } from '../types';

// set initial time from url as table param
const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const timeString = params.get("time");
const timeInt = parseInt(timeString || "");
const timeFromUrl = timeInt ? timeInt * 1000 : 0; // convert to milliseconds

const defaultState: State = {
  status: "preparing",
  start: 0,
  time: timeFromUrl || 441000, // 7:21 as default
  activePlayer: "",
  activePlayerTimeFrom: 0,
  clientActivePlayerTimeFrom: 0,
  players: [],
};

export default function App() {

  const { ws, status, channel, setChannel, sendMessage } = useWS();

  const [state, setState] = useState<State>(defaultState);
  const [name, setName] = useState(localStorage.getItem("player-name") || "Karel");

  const [isFullScreen, setIsFullScreen] = useState(document.fullscreenElement !== null);
  const [timeActivePlayer, setTimeActivePlayer] = useState(0);

  const refTimeDiff = useRef(0);

  const activePlayerId = state.status === "playing" ? state.activePlayer : ws.getId();

  const isActivePlayer = state.activePlayer === ws.getId();
  const player = state.players.find(p => p.id === ws.getId()) || { id: ws.getId(), name, status: "preparing", remainingTime: 0, endTime: 0 };

  function handleNameChange(name: string) {
    localStorage.setItem("player-name", name);
    setName(name);
    sendMessage({ type: "setPlayer", player: { ...player, name } });
  }

  function handleChangeChannel(channel: string) {
    setState(defaultState);
    setChannel(channel);
  }

  function handleTimeChange(time: number) {
    sendMessage({ type: "setState", state: { ...state, time } });
  }

  function handleFullScreen() {
    const element = document.documentElement;
    try {
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if ((element as any).webkitRequestFullscreen) {
          (element as any).webkitRequestFullscreen();
        }
      }
    } catch (error) {
      console.error("Fullscreen error:", error);
    }

    setIsFullScreen(!isFullScreen);
  }

  function handleCancelGame() {
    sendMessage({ type: "setState", state: { ...state, status: "preparing", players: state.players.map(p => ({ ...p, status: "preparing" })) } });
  }

  function handleRefreshPlayer() {
    sendMessage({
      type: "setState", state: {
        ...state,
        activePlayerTimeFrom: Date.now(),
      }
    });
  }

  function handlePause() {
    sendMessage({
      type: "setState", state: {
        ...state,
        status: "paused",
        activePlayerTimeFrom: Date.now(),
        players: state.players.map(p => p.id === activePlayerId ? ({ ...p, remainingTime: timeActivePlayer }) : p),
      }
    });
  }
  function handlePlay() {
    sendMessage({
      type: "setState", state: {
        ...state,
        status: "playing",
        activePlayerTimeFrom: Date.now(),
      }
    });
  }


  const handleNextPlayer = useMemo(() => () => {
    const id = ws.getId();
    const players = [...state.players];
    const player = players.find(p => p.id === state.activePlayer);
    if (!player) {
      console.error("Active player not found");
      return;
    }
    const nextPlayer = getNextPlayer(state, player);

    const endTime = Date.now();

    let remainingTime = state.clientActivePlayerTimeFrom + player.remainingTime - Date.now();
    if (remainingTime < 0) remainingTime = 0;

    if (nextPlayer === "finished") {
      setTimeActivePlayer(0);
      sendMessage({
        type: "setState", state: {
          ...state,
          status: "finished",
          players: players.map(p => p.id === id ? ({ ...p, remainingTime, endTime }) : p),
        }
      });
    } else {
      sendMessage({
        type: "setState", state: {
          ...state,
          activePlayer: nextPlayer.id,
          activePlayerTimeFrom: Date.now(),
          players: players.map(p => p.id === id ? ({ ...p, remainingTime, endTime }) : p),
        }
      });
    }
  }, [state, ws, sendMessage]);

  // screen lock
  const lock = state.status === "playing";
  const isVisible = document.visibilityState === "visible";
  useEffect(() => {
    const wakeLockRef: { current: any } = { current: null };

    if (lock && isVisible) {
      (async () => {
        try {
          if ("wakeLock" in navigator) {
            const wakeLock = await (navigator as any).wakeLock.request("screen");
            wakeLockRef.current = wakeLock;
          }
        } catch (error) {
          console.error("Screen lock error:", error);
        }
      })();
    }

    return () => {
      try {
        wakeLockRef.current?.release();
      } catch (error) {
        console.error("Screen lock release error:", error);
      }
    }
  }, [lock, isVisible]);

  // time handling
  useEffect(() => {
    if (state.status === "playing") {
      const activePlayer = state.players.find(p => p.id === state.activePlayer);
      if (!activePlayer) {
        console.error("Active player not found");
        return;
      }

      const timeActivePlayer = state.clientActivePlayerTimeFrom + activePlayer.remainingTime - Date.now();
      setTimeActivePlayer(timeActivePlayer);

      const interval = setInterval(() => {
        const timeActivePlayer = state.clientActivePlayerTimeFrom + activePlayer.remainingTime - Date.now();
        setTimeActivePlayer(timeActivePlayer);

        if (state.activePlayer === ws.getId() && timeActivePlayer < 0) {
          handleNextPlayer();
        }
      }, 250);
      return () => clearInterval(interval);
    }
  }, [state, handleNextPlayer, ws]);

  // message handling
  useWSOnMessage((event) => {
    setState(state => {
      const id = ws.getId();
      const name = localStorage.getItem("player-name") || "Karel";
      const player = state.players.find(p => p.id === id) || { id, name, status: "preparing", remainingTime: 0, endTime: 0 };

      if (event.type === "enter") {
        // I enter the channel
        if (event.id === id) {
          sendMessage({ type: "getState" });
          if (state.status === "preparing") {
            setState(defaultState);
            sendMessage({ type: "setPlayer", player });
          }
        }

        if (event.isMaster) {
          sendMessage({ type: "getSync", now: Date.now() });
        }
      } else if (event.type === "leave") {
        // I leave the channel
        if (event.id === id) {
          setState(defaultState);
        } else if (event.isMaster) {
          if (state.status === "preparing") {
            sendMessage({
              type: "setState", state: {
                ...state,
                players: state.players.filter(p => p.id !== event.id),
              }
            });
          } else {
            // wait to return
          }
        }

      } else if (event.type === "message") {
        const message = event.message;

        if (message.type === "getSync") {
          // TODO: sync solved with new state message
          refTimeDiff.current = Date.now() - message.now;
          console.log("Time diff:", refTimeDiff.current);

        } else if (message.type === "getState") {
          if (event.isMaster) {
            sendMessage({ type: "setState", state });
          }
        } else if (message.type === "setState") {
          // sync time 
          if (message.state.activePlayerTimeFrom !== state.activePlayerTimeFrom) {
            message.state.clientActivePlayerTimeFrom = Date.now();
          } else {
            message.state.clientActivePlayerTimeFrom = state.clientActivePlayerTimeFrom;
          }

          // set new state from message
          state = message.state;

          if (event.isMaster) {
            // all players are ready and game is not started
            if (
              message.state.status === "preparing" &&
              message.state.players.length > 0 &&
              message.state.players.reduce((is, p) => p.status === "ready" ? is : false, true)
            ) {
              const activePlayer = message.state.players[0].id;
              sendMessage({
                type: "setState", state: {
                  ...message.state,
                  status: "playing",
                  start: Date.now(),
                  activePlayer,
                  activePlayerTimeFrom: Date.now(),
                  players: message.state.players.map(p => ({ ...p, remainingTime: message.state.time })),
                }
              });
            }
          } else {

            // I am not in state
            if (message.state.status === "preparing" && !message.state.players.find(p => p.id === id)) {
              sendMessage({ type: "setPlayer", player });
            }
          }

        } else if (message.type === "getPlayers") {
          sendMessage({ type: "setPlayer", player });

        } else if (message.type === "setPlayer") {
          if (event.isMaster) {
            if (state.status !== "playing") {

              let newState = state;
              if (state.players.find(p => p.id === message.player.id)) {
                // already exists
                newState = {
                  ...state,
                  players: state.players.map(p => p.id === message.player.id ? message.player : p),
                };
              } else {
                // is new
                newState = {
                  ...state,
                  players: [...state.players, message.player],
                };
              }

              if (!isEqual(newState, state)) {
                sendMessage({ type: "setState", state: newState });
              }
            }
          }
        }
      }

      return state;
    });
  }, []);


  return <div style={{
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
    position: "relative",
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#EEE",
    borderRadius: "16px",
  }}>

    {(state.status === "playing" || state.status === "paused") && <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      position: "absolute",
      zIndex: 1,
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      padding: "8px",
    }}>
      <div style={{
        height: `${(isActivePlayer ? timeActivePlayer : player.remainingTime) / state.time * 100}%`,
        borderRadius: "12px",
        backgroundColor: getColor(state.players.findIndex(p => p.id === player.id)),
        opacity: isActivePlayer ? 0.5 : 0.3,
        transition: "0.5s",
      }} />
    </div>}

    <div style={{
      position: "relative",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    }}>
      <ConnectionStatus isMaster={ws.getMaster() === ws.getId()}>{status}</ConnectionStatus>

      <h1>Mathesso clock</h1>

      {state.status === "playing" && <>
        <div style={{
          position: "absolute",
          top: "46px",
          left: "16px",
        }}>
          <button style={{ display: "block", width: "48px" }} onClick={handleRefreshPlayer}>↻</button>
          <button style={{ display: "block", width: "48px", marginTop: "12px", paddingTop: "6px" }} onClick={handlePause}>
            <span style={{ display: "inline-block", backgroundColor: "white", width: "3px", height: "12px", marginRight: "3px" }} />
            <span style={{ display: "inline-block", backgroundColor: "white", width: "3px", height: "12px" }} />
          </button>
        </div>

      </>}
      {(state.status === "playing" || state.status === "paused") && <div style={{
        position: "absolute",
        top: "46px",
        right: "16px",
      }}>
        <button onClick={handleCancelGame}>✕</button>
      </div>}
      {state.status === "preparing" && <div style={{
        position: "absolute",
        top: "46px",
        right: "16px",
      }}>
        {!isIphone() && <button onClick={handleFullScreen}>
          {isFullScreen && <svg style={{ marginBottom: "-2px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
          </svg>}
          {!isFullScreen && <svg style={{ marginBottom: "-2px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
            <path d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707m0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707" />
          </svg>}
        </button>}
      </div>}

      {state.status === "preparing" && <div style={{ padding: "8px 16px" }}>
        <label>Your name:</label>
        <input style={{ fontSize: "24px" }} type="text" value={name} onChange={e => handleNameChange(e.target.value)} />

        <label>Table name:</label>
        <input type="text" value={channel} onChange={e => handleChangeChannel(e.target.value)} />

        <label>Time for each one:</label>
        <div className='input'>
          <TimePicker value={getMoment(state.time)} onChange={time => handleTimeChange(getTime(time))} />
        </div>
      </div>}

      {state.status !== "preparing" && player.status === "preparing" && <div style={{ padding: "8px 16px" }}>
        <label>Table name:</label>
        <input type="text" value={channel} onChange={e => handleChangeChannel(e.target.value)} />
      </div>}

      {state.status !== "preparing" && <>
        <h2>{player.name}</h2>
        <h3>
          <Time>{player.id === state.activePlayer ? timeActivePlayer : player.remainingTime}</Time>
        </h3>
      </>}

      <div style={{
        flexGrow: 1,
        flexBasis: "100px",
        padding: "8px 16px",
        overflow: "auto",
      }}>
        <div style={{
          borderTop: "1px solid #AAA",
          borderBottom: "1px solid #AAA",
        }}>
          {state.players.map((player, i) => <div key={i}>
            <div style={{ display: "flex", alignItems: "center", margin: "4px", }}>
              <div style={{ width: "24px", fontWeight: player.id === activePlayerId ? "bold" : "normal" }} >{i + 1}.</div>
              <div style={{ width: "150px", textAlign: "right", paddingRight: "16px", fontWeight: player.id === activePlayerId ? "bold" : "normal" }}>{player.name}</div>

              <Bar
                color={getColor(i)}
                max={state.status === "preparing" ? 1 : state.time}
                value={state.status === "preparing" ? 1 :
                  state.status === "finished" ? 0 :
                    state.activePlayer === player.id ? timeActivePlayer : player.remainingTime}
                text={state.status === "preparing" ? (player.status === "preparing" ? "Pending" : "Ready") :
                  state.status === "finished" ? getTimeFormat(player.endTime - state.start) :
                    undefined}
              />
            </div>
            {state.status === "preparing" && i < state.players.length - 1 && <div style={{ marginLeft: "150px" }}>
              <button onClick={() => {
                const players = [...state.players];
                players[i] = players[i + 1];
                players[i + 1] = player;
                sendMessage({ type: "setState", state: { ...state, players } });
              }}>⇧ ⇩</button>
            </div>}
          </div>)}
        </div>
      </div>


      {state.status === "preparing" && <TheButton disabled={player.status === "ready"} onClick={() => {
        sendMessage({ type: "setPlayer", player: { ...player, status: "ready" } });
      }}>
        Ready to play
      </TheButton>}

      {state.status === "playing" && <TheButton touch disabled={!isActivePlayer} onClick={handleNextPlayer}>
        {isActivePlayer ? "Next player" : `${state.players.find(p => p.id === state.activePlayer)?.name || "Somebody"} is on the move`}
      </TheButton>}

      {state.status === "paused" && <TheButton onClick={handlePlay}>
        ▶
      </TheButton>}

      {state.status === "finished" && <div>
        Game over, time's up <br />
        <TheButton onClick={handleCancelGame}>Return</TheButton>
      </div>}
    </div>
  </div>;
}

function getNextPlayer(state: State, activePlayer: Player): Player | "finished" {
  const players = state.players.length;
  const activeIndex = state.players.findIndex(p => p.id === activePlayer.id);
  for (let i = 1; i <= state.players.length; i++) {
    const nextIndex = (activeIndex + i) % players;
    const nextPlayer = state.players[nextIndex];
    if (nextPlayer.remainingTime > 0) {
      return nextPlayer;
    }
  }

  return "finished";
}

function ConnectionStatus({ children, isMaster }: { children: "connecting" | "connected" | "closed" | "error", isMaster: boolean }) {
  return <div style={{
    backgroundColor: children === "connected" ? "green" : "red",
    textAlign: "center",
    paddingBottom: "4px",
    paddingTop: "4px",
    borderRadius: "16px 16px 0 0",
    opacity: 0.8,
    fontSize: "12px",
  }}>
    {children === "connected" ? "Connected" : children === "error" ? "Something has gone wrong with the connection" : "Connecting..."}
    {isMaster ? "*" : ""}
  </div>;
}

function Bar({ value, max, color, text }: { value: number, max: number, color: string, text?: string }) {
  return <div style={{ position: "relative", flexGrow: 1, height: "25px", backgroundColor: "lightgray", borderRadius: "8px", overflow: "hidden" }}>
    <div style={{ transition: "0.5s", width: `${value / max * 100}%`, height: "100%", backgroundColor: color, borderRadius: "8px", textAlign: "center", overflow: "visible", paddingTop: "2px" }}>
      <span style={{ whiteSpace: "nowrap", paddingLeft: "8px", paddingRight: "8px", fontWeight: "bold" }}>
        {text ? text : getTimeFormat(value)}
      </span>
    </div>
  </div>;
}

function getMoment(time: number) {
  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor(time / 60000) % 60;
  const seconds = Math.floor(time / 1000) % 60;
  return moment().hours(hours).minutes(minutes).seconds(seconds);
}

function getTimeFormat(time: number) {
  if (time <= 0) return "Time's up";
  return getMoment(time).format("HH:mm:ss");
}

function getTime(time: string | Moment) {
  if (!time) time = "00:01:00";
  const m = typeof time === "string" ? moment(time, "HH:mm:ss") : moment(time);
  return m.hours() * 3600000 + m.minutes() * 60000 + m.seconds() * 1000;
}

function Time({ children }: { children: number }) {
  return <span>{getTimeFormat(children)}</span>;
}

function TheButton({ children, touch, onClick, disabled }: { children: string, touch?: boolean, onClick: () => void, disabled?: boolean }) {
  const refOnClick = useRef(disabled ? () => { } : onClick);
  refOnClick.current = disabled ? () => { } : onClick;

  const throttleOnClick = useMemo(() => {
    return throttle(() => refOnClick.current(), 1000, { trailing: false });
  }, []);

  return <div style={{
    paddingTop: "16px",
    width: "100%",
    display: "flex",
  }}>
    <button style={{
      flexGrow: 1,
      margin: "0 16px",
      height: "21svh",
      padding: "8px 16px",
      fontSize: "24px",
      borderRadius: "8px",
    }}
      disabled={disabled}
      onTouchStart={() => touch === true && throttleOnClick()}
      onClick={throttleOnClick}
    >{children}</button>
  </div>;
}

const colors = [
  "green",
  "rgb(90, 90, 255)", // blue
  "red",
  "yellow",
  "pink",
  "orange",
  "brown",
  "purple",
  "gray",
  "black",
];

function getColor(index: number) {
  return colors[index % colors.length];
}

function isIphone() {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

  return /iPhone/i.test(navigator.userAgent || navigator.vendor);
};